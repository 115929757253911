import React, {useState} from 'react'

import CardFilter from "../Cards/CardFilter";
import EventsImportedChart from "../EventsImportedChart/EventImportedChart";


function EventsImportedReport() {
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState('Today');
    const handleFilterChange = (filter: any) => {
        setFilter(filter);
    };
    return (
        <div className="card">
            <CardFilter filterChange={handleFilterChange}/>
            <div className="card-body">
                <h5 className="card-title">
                    Event imported by source reports <span>/{filter}</span>
                </h5>
                <EventsImportedChart/>
            </div>
        </div>
    )
}

export default EventsImportedReport