import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {CompanyInterface} from "../../@interfaces/CompanyInterface";
import {Button, Form, Table} from "react-bootstrap";
import {formatDateTimeWithTimeZone} from "../../utils/dateFormatter";
import GenerateApiKeyModal from "../../modals/GenerateApiKeyModal/GenerateApiKeyModal";


interface CompanyListViewProps {
    companies: CompanyInterface[];
}

function CompanyListView({companies}: CompanyListViewProps) {
    const {t, i18n} = useTranslation();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(companies.map(_company => false));
    const [showGenerateApiKeyModal, setShowGenerateApiKeyModal] = useState(false);
    useEffect(() => {
        setSelectedCheckboxes(companies.map(() => false));
        setSelectedAll(false);
    }, [companies]);
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(companies.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };

    const generateApiKey = () => {
        // Logique pour générer une clé API (peut être une requête à ton backend)
        return 'abcd1234-5678-90ef-ghij-klmnopqrst';
    };
    return (<>
        {<Table striped responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    <Form.Check
                        type="checkbox"
                        id={`select-all-checkbox`}
                        checked={selectedAll}
                        onChange={handleSelectAll}
                    />
                </th>
                <th>{t('common.name')}</th>
                <th>{t('common.phone')}</th>
                <th>{t('common.email')}</th>
                <th>{t('common.businessName')}</th>
                <th>{t('common.createdAt')}</th>
                <th>{t('common.action')}</th>
            </tr>
            </thead>
            <tbody>

            {companies.map((company: CompanyInterface, index) => (
                <tr key={company.id}>
                    <td>{index + 1}</td>
                    <td>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`checkbox-${company.id}`}
                            checked={selectedCheckboxes[index] || false}
                            onChange={() => handleSelectCheckbox(index)}
                        />
                    </td>
                    <td>{company.name}</td>
                    <td>{company.phone_number}</td>
                    <td>{company.email_address}</td>
                    <th>{company.business_name}</th>
                    <td>{formatDateTimeWithTimeZone(company.created_at, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                    <td className="justify-content-between">
                        <Button variant="outline-primary" size="sm" onClick={()=>setShowGenerateApiKeyModal(true)}>
                            <i className="bi bi-key-fill"></i>
                        </Button>
                        {/*<Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-trash"></i>
                        </Button>*/}
                    </td>
                </tr>
            ))
            }
            </tbody>
        </Table>

        }
        <GenerateApiKeyModal show={showGenerateApiKeyModal} handleClose={()=>setShowGenerateApiKeyModal(false)}
                             generateApiKey={generateApiKey}/>
    </>)
}

export default CompanyListView;