import React, {useState} from 'react'

import CardFilter from "../Cards/CardFilter";
import EventsImportedChart from "../EventsImportedChart/EventImportedChart";
import EventsByCategoryChart from "../EventsCategoryChart/EventByCategoryChart";


function EventsByCategoryReport() {
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState('Today');
    const handleFilterChange = (filter: any) => {
        setFilter(filter);
    };
    return (
        <div className="card">
            <CardFilter filterChange={handleFilterChange}/>
            <div className="card-body">
                <h6 className="card-title">
                    Events By categories <span>/{filter}</span>
                </h6>
                <EventsByCategoryChart/>
            </div>
        </div>
    )
}

export default EventsByCategoryReport