import React, {useState} from 'react'
import Chart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'

function EventsByCategoryChart() {
    const [data, setData] = useState<{
        series: number[];
        options: ApexOptions;
    }>({
        series: [31, 40, 28, 82, 52], // Données pour le graphique à secteurs
        options: {
            chart: {
                type: 'pie',
                height: 350,
            },
            labels: ['Concert', 'Festival', 'Danse', 'SLAM', 'Autres'], // Étiquettes pour le pie chart
            colors: ['#4154f1', '#2eca6a', '#ff771d', '#f76b1c', '#ffc107'], // Couleurs pour chaque secteur
            dataLabels: {
                enabled: true, // Activer les étiquettes de données
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val} ventes`, // Formater les valeurs affichées dans l'info-bulle
                },
            },
            legend: {
                position: 'bottom', // Position de la légende
            },
        },
    });

    return (

        <Chart
            options={data.options}
            series={data.series}
            type={data.options.chart?.type}
            height={data.options.chart?.height}
        />

    );
}

export default EventsByCategoryChart
