import React, { useState } from 'react'
import CardFilter from './CardFilter'
import './Card.scss'

interface CardProps{
    card:any
}
function Card({ card }:CardProps) {
    const [filter , setFilter] = useState('Today');
    const handleFilterChange = (filter:any) =>{
        setFilter(filter);
    };
  return (
    <div className="col-xxl-4 col-md-6 mt-2">
        <div className="card info-card sales-card">
            <CardFilter filterChange={handleFilterChange} />
            <div className="card-body">
                <p className='card-title'>
                    {card.name}
                </p>
                <div className="d-flex align-content-center">
                    <div className="card-icon rounded-circle d-flex align-content-center justify-content-center">
                        <i className={card.icon}></i>
                    </div>
                    <div className="ps-3 pt-3">
                        <h6>
                            {card.name === 'Revenue'
                            ? '$' + card.amount.toLocaleString('en-US')
                            : card.amount.toLocaleString('en-US')
                            }
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Card