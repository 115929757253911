import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {Form, Table,Button} from "react-bootstrap";
import {formatDateTimeWithTimeZone} from "../../utils/dateFormatter";
import {RegistrationRequestsInterface} from "../../@interfaces/RegistrationRequestsInterface";
import CompanyInitializerFormModal from "../../modals/CompanyInitializerFormModal/CompanyInitializerFormModal";
import {useNavigate} from "react-router-dom";

interface RegistrationRequestsListViewProps {
    registrationRequests: RegistrationRequestsInterface[];
}

function RegistrationRequestsListView({registrationRequests}: RegistrationRequestsListViewProps) {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(registrationRequests.map(registrationRequest => false));
    const [requestToBeConvertToClient, setRequestToBeConvertToClient] = useState<any>(null)
    const [showInitializerFormModal, setShowInitializerFormModal] = useState(false);
    useEffect(() => {
        setSelectedCheckboxes(registrationRequests.map(() => false));
        setSelectedAll(false);
    }, [registrationRequests]);
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(registrationRequests.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };

    return (<>
        {<Table striped responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    <Form.Check
                        type="checkbox"
                        id={`select-all-checkbox`}
                        checked={selectedAll}
                        onChange={handleSelectAll}
                    />
                </th>
                <th>{t('common.name')}</th>

                <th>{t('common.phone')}</th>
                <th>{t('common.email')}</th>
                <th>{t('common.businessName')}</th>
                <th>{t('common.contactPerson')}</th>
                <th>{t('common.contactPersonEmail')}</th>
                <th>{t('common.createdAt')}</th>
                <th>{t('common.action')}</th>
            </tr>
            </thead>
            <tbody>

            {registrationRequests.map((registrationRequest: RegistrationRequestsInterface, index) => (
                <tr key={registrationRequest.id}>
                    <td>{index + 1}</td>
                    <td>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`checkbox-${registrationRequest.id}`}
                            checked={selectedCheckboxes[index] || false}
                            onChange={() => handleSelectCheckbox(index)}
                        />
                    </td>
                    <td>{registrationRequest.data.company.name}</td>
                    <td>{registrationRequest.data.company.phone_number}</td>
                    <td>{registrationRequest.data.company.email_address}</td>
                    <th>{registrationRequest.data.company.business_name}</th>
                    <th>{`${registrationRequest.data.account.firstname} ${registrationRequest.data.account.lastname}`}</th>
                    <th>{registrationRequest.data.account.email_address}</th>
                    <td>{formatDateTimeWithTimeZone(registrationRequest.created_at, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                    <td className="justify-content-between">
                        <Button variant="outline-primary" size="sm" onClick={() => {
                            setRequestToBeConvertToClient(registrationRequest.data)
                            setShowInitializerFormModal(true)
                        }}>
                            <i className="bi bi-person-add "></i>
                            </Button>
                        {/*  <Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-trash"></i>
                        </Button>*/}
                    </td>
                </tr>
                ))
            }
            </tbody>
        </Table>
        }

<CompanyInitializerFormModal showModal={showInitializerFormModal}
                             initialValues={requestToBeConvertToClient}
                             onHide={() => setShowInitializerFormModal(false)}
                             fetchCompanies={()=> navigate('/companies')}/>

</>)
}

export default RegistrationRequestsListView;