import axiosClient from "./AxiosInstance";


import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {isEmpty} from "../utils/utils";
import {AccountInterface} from "../@interfaces/AccountInterface";


// Define the expected response structure
interface AccountsResponse {
    data: AccountInterface[];
    headers: any;
}


export default class AccountsService {
    static async retrieveAccounts({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    }): Promise<AccountsResponse> {
        try {
            const formattedFilters = AccountsService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/accounts?${!isEmpty(formattedFilters) ? formattedFilters : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

    static async retrieveAccount(uuid: number) {
        try {
            const response = await axiosClient.get(`/accounts/${uuid}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    static formatFiltersToQueryString(filters: any) {
        if (!isEmpty(filters)) {
            const filter = {
                name: filters.name
            }

            //return new URLSearchParams(Object.entries(filter)).toString();
            // Remove all undefined keys

            const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.length != 0 && value.every((val: any) => val !== undefined && val !== null && val !== '')
                    } else {
                        return value !== '' && value !== undefined && value !== null
                    }
                })
            );

            // Initialize an array to hold query string parts
            const queryParts: string[] = [];

            // Build the query string manually
            for (const [key, value] of Object.entries(filteredFilter)) {
                if (Array.isArray(value)) {
                    // If value is an array, add each item with the same key
                    value.forEach((item) => {
                        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
                    });
                } else {
                    // For single values, just add it directly
                    queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
                }
            }

            // Join all parts with '&' to form the final query string
            return queryParts.join('&');
        }

        return filters;
    }
}