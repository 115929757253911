import React, {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import ReadOnlyInputWithCopy from "../../components/ReadOnlyInputWithCopy/ReadOnlyInputWithCopy";

interface GenerateApiKeyModalProps {
    show: boolean,
    handleClose: () => void,
    generateApiKey: () => any,
}

function GenerateApiKeyModal({show, handleClose, generateApiKey}: GenerateApiKeyModalProps) {
    const [apiKey, setApiKey] = useState(null);
    const handleGenerateKey = () => {
        const newApiKey = generateApiKey(); // Génère une clé API via une fonction
        setApiKey(newApiKey);
    };

    const handleCloseWithWarning = () => {
        const userConfirmed = window.confirm(
            "Une fois la modale fermée, vous ne pourrez plus revenir en arrière et la clé ne sera plus disponible. Voulez-vous vraiment fermer ?"
        );
        if (userConfirmed) {

            setApiKey(null)
            handleClose(); // Appelle la fonction pour fermer la modale
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleCloseWithWarning}>
                <Modal.Header closeButton>
                    <Modal.Title>Générer une clé API</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {apiKey && (
                            <>
                                <Form.Group controlId="formApiKey">
                                    <Form.Label>Identifiant Api</Form.Label>
                                    <ReadOnlyInputWithCopy value={apiKey} type={"text"}/>
                                </Form.Group>
                                <Form.Group controlId="formApiKey">
                                    <Form.Label>Mot de passe Api</Form.Label>
                                    <ReadOnlyInputWithCopy value={apiKey} type={"password"}/>
                                </Form.Group>
                            </>
                        )}

                        <div className="text-center mt-3">
                            <Button variant="primary" onClick={handleGenerateKey}>
                                Générer une nouvelle clé API
                            </Button>
                        </div>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseWithWarning}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default GenerateApiKeyModal;