import {Link, Navigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AuthenticationService from "../../../services/AuthenticationService";
import './LoginPage.scss'; // Assurez-vous d'ajouter ce fichier CSS dans le même répertoire
// @ts-ignore
import logo from '../../../assets/logo-ticketr.svg'
import {Button, Card, Col, Container, Dropdown, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function LoginPage(): JSX.Element {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);
    const {t, i18n} = useTranslation();
    const handleSelectLanguage = (lang: any) => {
        i18n.changeLanguage(lang);
    };


    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const isAuth = await AuthenticationService.isAuthenticated();
                setAuthenticated(isAuth);
            } catch (error) {
                console.error("Error checking authentication:", error);
                setAuthenticated(false);
            }
        };

        checkAuthentication();
    }, []);

    const handleLogin = async () => {
        try {
            await AuthenticationService.loginZitadel();
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    if (authenticated) {
        return <Navigate to="/events" replace={true}/>;
    }

    return (
        <Container fluid={true} style={{height: "100vh"}}>
            <Row className="h-100">
                <Col xs={12} md={6} lg={6} sm={12} className="login-left"></Col>

                {/* Section de droite */}
                <Col
                    xs={12}
                    md={6}
                    lg={6}
                    sm={12}
                    className="registration-right d-md-flex flex align-items-center justify-content-center"
                    style={{position: "relative"}} // Ajout pour permettre la position absolue à l'intérieur
                >
                    {/* Conteneur pour le logo et le sélecteur de langue */}
                    <div
                        className="d-flex justify-content-between align-items-center w-100 px-3 mb-4"
                        style={{
                            position: "absolute", // Position absolue dans la colonne droite
                            top: 0, // Collé en haut de la colonne droite
                            left: 0,
                            right: 0,
                            zIndex: 1000, // Assurez-vous qu'elle reste au-dessus des autres éléments
                            backgroundColor: "white", // Couleur de fond pour éviter que le texte soit masqué
                            padding: "10px",
                        }}
                    >
                        {/* Logo */}
                        <img
                            src={logo}
                            alt="Logo"
                            className="login-logo"
                            height={50} // Ajustez la hauteur si nécessaire
                        />

                        {/* Dropdown pour changer la langue */}
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {i18n.language.toUpperCase()}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleSelectLanguage("en")}>
                                    English
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleSelectLanguage("fr")}>
                                    Français
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {/* Contenu principal centré dans la colonne droite */}
                    <div className="text-center mt-5 pt-5 w-100">
                        <h3>{t("common.wellcomeOnTicketr")}</h3>
                        <p className="text-primary mt-4">{t("common.wellcomeText")}</p>

                        <div className="mt-3 text-center">
                            <p>
                                <i>{t("common.notHaveAccount")}</i>
                            </p>
                        </div>

                        <Row className="g-4 justify-content-center">
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <Card className="h-100 shadow-sm setting-cards">
                                    <Card.Body className="text-center">
                                        <i
                                            className={`bi bi-clock mb-3`}
                                            style={{fontSize: "2rem"}}
                                        ></i>
                                        <Card.Title>{t("common.trial")}</Card.Title>
                                        <Card.Text>
                                            <a href="#" onClick={handleLogin} className="mx-2">
                                                {t("common.testForFree")}
                                            </a>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <Card className="h-100 shadow-sm setting-cards">
                                    <Card.Body className="text-center">
                                        <i
                                            className={`bi bi-star-fill mb-3`}
                                            style={{fontSize: "2rem"}}
                                        ></i>
                                        <Card.Title>{t("common.pro")}</Card.Title>
                                        <Card.Text>
                                            <Link to="/auth/registration">
                                                {t("common.registerAsPro")}
                                            </Link>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="g-4 justify-content-center">
                            <Col xs={12} sm={6} md={8} lg={8}>
                                <Button
                                    onClick={handleLogin}
                                    className="mt-3 w-100"
                                    variant="primary"
                                    size="sm"
                                >
                                    {t("common.wellcomeLoginButtonText")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>


    )
        ;
}

export default LoginPage;
