const commonTranslation = {
    pleaseSelect: "Please select...",
    callSign: "Call Sign",
    email: "Email",
    emailPlaceholder: "Email",
    website: "Website",
    vat: "VAT",
    name: "Name",
    namePlaceHolder: "Enter your name",
    firstNamePlaceHolder: "Enter your first name",
    firstname: "First Name",
    cityOfBirth: "City of Birth",
    countryOfBirth: "Country of Birth",
    birthday: "Date of Birth",
    share: "Share",
    tags: "Category",
    owner: "Owner",
    venue: "Venue",
    applyFilter: "Apply Filter",
    resetFilter: "Reset Filters",
    filters: "Search Filters",
    localStartDate: "Start Date",
    localEndDate: "End Date",
    loading: "Loading...",
    pagination: "Displaying {{startItemNumber}} to {{endItemNumber}} of {{totalItems}} items",
    save: "Save",
    close: "Close",
    password: "Password",
    noAccount: "Don't have an account yet?",
    createAccount: "Create an account",
    forgotPassword: "Forgot Password",
    canceled: "Cancel",
    pages: "pages",
    perPage: "per page",
    adminInfo: "Administrative Information",
    imatProof: "Registration or publication proofs",
    managementInformation: "Management Information",
    paymentMethod: "Payment Method",
    myEvents: "My Events",
    shareWithMe: "Shared with me",
    title: "Title",
    action: "Action",
    importedAt: "Imported on",
    dateShortWithSecond: "{{date, SHORT_WITH_SECONDS}}",
    status: "Status",
    inputRequired: "This field is required",
    invalidEmail: "Invalid email address",
    siret: "Siret",
    pleaseEnterPhoneNumber: "Phone",
    attachmentRequired: "The documents: ({{value}}) are mandatory",
    localOffices: "Local Offices",
    addOffice: "Add office",
    removeOffice: "Remove office",
    invalidPhoneNumber: "Invalid Phone Number",
    manager: "Manager",
    addManager: "Add Manager",
    removeManager: "Remove manager",
    paymentMethods: "Payment Methods",
    invoices: "Invoices",
    invoiceNumber: "Invoice Number",
    unitPrice: "Unit Price",
    invoiceSentDate: "Invoice Sent Date",
    pleaseSelectPaymentMode: "Please select a payment method",
    transfert: "Transfer",
    card: "Card",
    pleaseEnterCardNumber: "Please enter the card number",
    cardNumber: "Card Number",
    expirationDate: "Expiration Date",
    CVV: "CVV",
    inputMinLength: "This field must contain at least {{value}} characters",
    inputMaxLength: "This field must contain a maximum of {{value}} characters",
    invalidExpirationDateFormat: "The expected format is MM/YY, MM=Month, YY=Year",
    download: "Download",
    iban: "IBAN",
    initializeCompanyAccount: "Account Initialization",
    businessName: "Business Name",
    personContact: "Contact Person",
    pleaseEnterCompanyName: "Enter the company or account name",
    pleaseEnterCompanyBusinessName: "Enter the business name",
    pleaseEnterCompanyPersonContact: "Enter the contact person's name",
    pleaseEnterCompanyEmail: "Enter the contact email",
    add: "Add",
    accountType: "Account Type",
    lastLogin: "Last Login",
    company: "Company",
    createdAt: "Created at",
    updatedAt: "Updated at",
    lastUpdatedAt: "Last Updated at",
    source: "Source",
    address: "Address",
    organisation: "Company",
    roles: "Roles",
    inputURLInvalid: "Invalid URL ex: https://ticketr;fr",
    position: "Your position",
    pleaseEnterYourPosition: "Please enter your position",
    legalInformationRetrievingMethod: "How would you like to provide your organization's legal information?",
    prev: "Prev",
    next: "Next",
    manual: "Manually later",
    byMySiren: "With my n° Siren",
    byMySiret: "With my n° Siret",
    pleaseEnter: "Please enter your",
    retrieve: "Retrieve",
    invalidSiretNumber: "The entered number is incorrect",
    inputSiretLength: "This field must contain at least {{value}} characters",
    stepOne: "Step 1 ",
    stepTwo: "Step 2 ",
    stepThree: "Step 3 ",
    saveAndRegister: "Save and register",
    formSubmitted: "Your form has been submitted successfully",
    willBeContacted: "You will be Contacted as soon as possible",
    wellcomeText: "Join the Ticketr community and gain access to numerous additional sales channels, all for free.",
    wellcomeLoginButtonText: "Login",
    backToLogin: "Back to Login",
    testForFree: "Test For Free",
    notHaveAccount: "Don't have an account? No problem!",
    registerAsPro: "Register as a professional",
    eventDetails: "Event Details",
    pleaseEnterToAdd: "Type something and press Enter to add",
    eventBruteDetails: "Raw Data",
    linkToTicketing: "Access Ticketing",
    pleaseEnterName: "Enter a name",
    pleaseEnterAddress: "Enter an address",
    phone: "Phone",
    export: "Export",
    exportTo: "Export to {{value}}",
    errorWhileShareEvent: "Oops! Share failed",
    city: "City",
    postalCode: "Postal Code",
    country: "Country",
    moreDetails: "More details",
    wellcomeOnTicketr: "Welcome on Ticketr",
    pro: "Pro",
    trial: "Trial",
    companyName: "Your company name",
    yourFirstname: "Your first name",
    yourLastName: "Your last name",
    yourEmail: "Your email address",
    pleaseEnterYourLastName: "Enter your last name",
    pleaseEnterYourFirstName: "Enter your first name",
    plan: 'Plan',
    inputMustBeManual: '',
    errorWhileSubmitRequestForm: 'An error has occurred during submission. Please try again; if it persists, please send an email to contact@ticketr.fr',
    lastname: "Lastname",
    contactPerson: "Contact Person",
    pleaseEnterLastName: "Enter a last name",
    pleaseEnterEmail: "Enter an email address",
    pleaseEnterBusinessName: "Enter a business name",
    siretNumber: "Siret number",
    pleaseEnterSiretNumber: "Enter siret number",
    pleaseEnterCityName: "Please enter city name",
    pleaseEnterPostalCode: "Please enter postal code",
    companyRegisteredFailed: "An error has occurred during submission. Please verify siret number and try again.",
    companyRegisteredSuccessfully: "Company registered successfully",
    trialPeriodMessage: "You are currently using the trial version of Ticketr. Some features may be limited.",
    venueDetails:"Venue details",
    contactPersonEmail: "Contact person email",
    generateCurlCommand: "Generate curl command",
    copyFilter:"Copy filter",
    filterCopySuccessfully:"Filter copied to the clipboard!",
    companyRegisteredErrorDuplicatedEntry:"Company with same name or email address already exists!",
    apiKeyIdentifier: "API Key Identifier",
    callCount: "Call Count",
    expiredAt:"Expired at"

}
export default commonTranslation;
