import React, {useEffect, useState} from 'react'
import Card from './Card';

const cardData = [
    {
        "_id": 1,
        "name": "Owned Events",
        "icon": "bi bi-calendar-event",
        "amount": 145,
        "percentage": 0.12,
        "active": true
    },
    {
        "_id": 2,
        "name": "Not owned events",
        "icon": "bi bi-share",
        "amount": 3246,
        "percentage": 0.08,
        "active": false
    },
    {
        "_id": 3,
        "name": "Api keys",
        "icon": "bi bi-key",
        "amount": 1244,
        "percentage": -0.11,
        "active": false
    },

    {
        "_id": 4,
        "name": "Owned venues",
        "icon": "bi bi-key",
        "amount": 1244,
        "percentage": -0.11,
        "active": false
    },

    {
        "_id": 5,
        "name": "Not owned venues",
        "icon": "bi bi-key",
        "amount": 1244,
        "percentage": -0.11,
        "active": false
    },

]

function Cards() {
    const [cards, setCards] = useState<Array<any>>([])

    const fetchData = () => { // de keda arrow function
        setCards(cardData)
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                cards && cards.length > 0 &&
                cards.map(card => <Card key={card._id} card={card}/>)
            }
        </>
    );
}

export default Cards