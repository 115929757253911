import React from "react";
import EventsImportedReport from "../../components/Dashboard/EventsImportedReport/EventsImportedReport";
import Cards from "../../components/Dashboard/Cards/Cards";
import './DashboardView.scss'
import EventsByCategoryReport from "../../components/Dashboard/EventsByCategoryReport/EventsByCatoryReport";
function DashboardView(){
    return (
    <section className="dashboard section">
        <div className="row">
            <div className="col-lg-8">
                <div className="row">
                   <Cards/>
                  <div className="col-12 mt-3">
                    <EventsImportedReport/>
                  </div>
                </div>
            </div>
            <div className="col-lg-4">
                <EventsByCategoryReport/>
            </div>
        </div>
    </section>
  )
}
export default DashboardView;