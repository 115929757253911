import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {AccountInterface} from "../../@interfaces/AccountInterface";
import {Button, Form, Table} from "react-bootstrap";
import {formatDateTimeWithTimeZone} from "../../utils/dateFormatter";


interface AccountListViewProps {
    accounts: AccountInterface[];
}

function AccountListView({accounts}: AccountListViewProps) {
    const {t,i18n} = useTranslation();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(accounts.map(Account => false));

    useEffect(() => {
        setSelectedCheckboxes(accounts.map(() => false));
        setSelectedAll(false);
    }, [accounts]);
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(accounts.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };
    return (<>
        {<Table striped responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    <Form.Check
                        type="checkbox"
                        id={`select-all-checkbox`}
                        checked={selectedAll}
                        onChange={handleSelectAll}
                    />
                </th>
                <th>{t('common.name')}</th>
                <th>{t('common.email')}</th>
                <th>{t('common.organisation')}</th>
                <th>{t('common.plan')}</th>
                {/*<th>{t('common.lastLogin')}</th>*/}
                <th>{t('common.createdAt')}</th>
                <th>{t('common.action')}</th>
            </tr>
            </thead>
            <tbody>

            {accounts.map((account: AccountInterface, index) => (
                <tr key={account.id}>
                    <td>{index + 1}</td>
                    <td>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`checkbox-${account.id}`}
                            checked={selectedCheckboxes[index] || false}
                            onChange={() => handleSelectCheckbox(index)}
                        />
                    </td>
                    <td>{account.name}</td>
                    <td>{account.email_address}</td>

                    <th>{account.owned_company.name}</th>
                    <th>{account.owned_company.plan?.toUpperCase()}</th>

                    <td>{formatDateTimeWithTimeZone(account.created_at, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                    <td>
                        {/*
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-eye-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-trash"></i>
                        </Button>*/}
                    </td>
                </tr>
            ))
            }
            </tbody>
        </Table>}
    </>)
}

export default AccountListView;